import { ApolloProvider } from '@apollo/client';
import * as Sentry from '@sentry/nextjs';
import type { AppProps } from 'next/app';
import { useApollo } from '../utils/apollo';
import '../styles/globals.css';

declare global {
  interface Window {
    adsbygoogle: { [key: string]: unknown }[];
  }
}

function MyApp({ Component, pageProps }: AppProps) {
  const apolloClient = useApollo(pageProps);
  if (process.env.NODE_ENV === 'production') {
    Sentry.init({
      dsn: 'https://6571ad42cca14b7a87639dbffcb8de79@o880918.ingest.sentry.io/6103845',

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 0.7,
    });
  }

  return (
    <ApolloProvider client={apolloClient}>
      <Component {...pageProps} />
    </ApolloProvider>
  );
}

export default MyApp;
